define(['app'], function(app) {

  // pure functions that don't rely on component variables
  const resetStyle = (el, sname) => el.style[sname] = '';
  const setStyle = (el, sname, val) => el.style[sname] = val;
  const getStyle = (el, sname) => el.style[sname];

  const getCssHeight = (el) => parseInt(getStyle(el, 'height'));
  const setCssHeight = (el, val) => setStyle(el, 'height', val + 'px');
  const resetCssHeight = (el) => resetStyle(el, 'height');

  const hide = (el) => el.classList.add('hide');
  const show = (el) => el.classList.remove('hide');

  const getRenderedHeight = el => el.offsetHeight;

  const readmore = () => {
    const component = {};
    component.app = app;

    component.computed = {
      get collapsedHeight() {
        return getRenderedHeight(component.children.footer) + component.props.contentMaxHeight;
      },
      get expandedHeight() {
        return getRenderedHeight(component.children.content) + getRenderedHeight(component.children.footer);
      },
      get isCollapsed() {
        return getCssHeight(component.element) === component.computed.collapsedHeight;
      },
      get isTrimNeeded() {
        return getRenderedHeight(component.children.content) > component.props.contentMaxHeight + getRenderedHeight(component.children.footer);
      },
    };

    const _config = {
      props: {
        contentMaxHeight: {
          selector: 'data-max-height',
          defaultValue: 50,
          transform: (n) => parseInt(n),
        },
        readMoreText: {
          selector: 'data-read-more-text',
          defaultValue: 'Read More',
        },
        readLessText: {
          selector: 'data-read-less-text',
          defaultValue: 'Read Less',
        }
      },
      children: {
        content: '.readmore_content',
        footer: '.readmore_footer',
        footerButton: '.readmore_footerButton',
      },
    };

    const _init = element => {
      component.element = element;

      component.children = {};
      for (let [childrenName, childrenSelector] of Object.entries(component.config.children)) {
        component.children[childrenName] = element.querySelector(childrenSelector);
      }

      component.props = {};
      for (let [propName, {selector, defaultValue, transform = x => x}] of Object.entries(component.config.props)) {
        component.props[propName] = transform(app.element.getAttribute(selector, component.element) || defaultValue);
      }

      if (component.computed.isTrimNeeded) {
        show(component.children.footer);
        component.attachListeners();
        component.collapse();
      } else {
        hide(component.children.footer);
        resetCssHeight(component.element);
      }

      return component;
    };

    const _attachListeners = () => {
      component.children.footerButton.addEventListener('click', component.toggle);
    };

    const _toggle = () => component.computed.isCollapsed ? component.expand() : component.collapse();

    const _collapse = () => {
      component.children.footerButton.innerHTML = component.props.readMoreText;
      component.children.footerButton.setAttribute('aria-expanded', 'false');
      setCssHeight(component.element, component.computed.collapsedHeight);
    };

    const _expand = () => {
      component.children.footerButton.innerHTML = component.props.readLessText;
      component.children.footerButton.setAttribute('aria-expanded', 'true');
      setCssHeight(component.element, component.computed.expandedHeight);
    };

    component.config = _config;
    component.init = _init;
    component.attachListeners = _attachListeners;
    component.toggle = _toggle;
    component.collapse = _collapse;
    component.expand = _expand;

    return component;
  };

  return readmore;
});
